import type { AppState } from "ee/reducers";
import React from "react";
import { default as CE_usePluginActionResponseTabs } from "ce/PluginActionEditor/components/PluginActionResponse/hooks/usePluginActionResponseTabs";
import { PluginType } from "entities/Plugin";
import { usePluginActionContext } from "PluginActionEditor";
import { useSelector } from "react-redux";
import { getIsAnvilEnabledInCurrentApplication } from "layoutSystems/anvil/integrations/selectors";
import { DEBUGGER_TAB_KEYS } from "components/editorComponents/Debugger/constants";
import { RagDocuments } from "ee/components/formControls/Rag";
import type { Datasource } from "entities/Datasource";
import { Flex } from "@appsmith/ads";

function usePluginActionResponseTabs() {
  const { datasource, plugin } = usePluginActionContext();
  const isAnvilEnabled = useSelector(getIsAnvilEnabledInCurrentApplication);
  const tabs = CE_usePluginActionResponseTabs();
  const ragDocumentsCount = useSelector((state: AppState) =>
    (datasource as Datasource)?.id
      ? state.ai.ragDocuments?.[(datasource as Datasource)?.id]?.length
      : 0,
  );

  if ([PluginType.AI].includes(plugin.type) && isAnvilEnabled) {
    // for anvil, we delete DATASOURCE_TAB
    const newTabs = tabs.filter(
      (tab) => !(tab.key === DEBUGGER_TAB_KEYS.DATASOURCE_TAB),
    );

    // add DATA_TAB for anvil to the first position
    return [
      {
        key: DEBUGGER_TAB_KEYS.DATA_TAB,
        title: "Data points",
        count: ragDocumentsCount,
        panelComponent: (
          <Flex padding="spaces-4">
            <RagDocuments
              datasourceId={(datasource as Datasource)?.id}
              isDeletedAvailable={false}
              workspaceId={datasource?.workspaceId}
            />
          </Flex>
        ),
      },
      ...newTabs,
    ];
  }

  return CE_usePluginActionResponseTabs();
}

export default usePluginActionResponseTabs;
