import type { PluginSettings } from "ee/constants/ModuleConstants";
import { getPluginSettingConfigs } from "ee/selectors/entitiesSelector";
import { PluginType } from "entities/Plugin";
import { useSelector } from "react-redux";

const whitelistedSettings: Record<string, string[]> = {
  [PluginType.API]: [
    "actionConfiguration.encodeParamsToggle",
    "actionConfiguration.pluginSpecifiedTemplates[0].value",
    "actionConfiguration.httpVersion",
    "actionConfiguration.timeoutInMillisecond",
  ],
  [PluginType.DB]: [
    "actionConfiguration.timeoutInMillisecond",
    "actionConfiguration.pluginSpecifiedTemplates[0].value",
    "actionConfiguration.formData.preparedStatement.data",
  ],
  [PluginType.SAAS]: ["actionConfiguration.timeoutInMillisecond"],
  [PluginType.JS]: [],
  [PluginType.REMOTE]: ["actionConfiguration.timeoutInMillisecond"],
  [PluginType.AI]: ["actionConfiguration.timeoutInMillisecond"],
  [PluginType.INTERNAL]: ["actionConfiguration.timeoutInMillisecond"],
};

interface UseWorkflowSettingsForm {
  pluginType: PluginType;
  pluginId: string;
}

/** Returns the settings form for a particular plugin type, for workflows we do not need all the settings as compared to the applications
 * Hence we filter out the settings that are not needed for workflows
 * @param pluginType - The type of the plugin
 * @param pluginId - The id of the plugin
 * @returns PluginSettings[] - The settings form for the plugin type
 * */
export function useWorkflowQuerySettings({
  pluginId,
  pluginType,
}: UseWorkflowSettingsForm) {
  const settingsConfig: PluginSettings[] =
    useSelector((state) => getPluginSettingConfigs(state, pluginId)) || [];

  return settingsConfig.map((settings) => {
    return {
      ...settings,
      children: (settings.children || []).filter((config) => {
        return whitelistedSettings[pluginType]?.includes(config.configProperty);
      }),
    };
  });
}
